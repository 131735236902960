import React from "react"
import SEO from "../../components/seo"
import CenterBreadCrumbs from "../../components/centerBreadCrumbs"

const AboutUs = () => {
  return (
    <>
      <SEO title="Just Kidd Inn Too - About Us" />

      <CenterBreadCrumbs
        centerName="Just Kiddinn Too"
        centerUrl="/justkiddinntoo/"
        subPageName="About Us"
      />

      <div className="container-fluid horizontal-section-container clearfix">
        <div className="row">
          <div className="col-sm-12">
            <div
              id="main-page-content"
              className="section-container main-page-content clearfix"
            >
              <div className="section-content">
                <h1 className="page_title">About Us</h1>

                <h4>Just Kiddinn Too</h4>

                <div id="content">
                  <p>
                    Our baby’s room is the hub of the centre, with its homely
                    ambience and friendly faces. The babies quickly develop very
                    fond and loving relationships with their teachers, which
                    installs a sense or security and promotes an optimal
                    learning environment. Babies are treated with respect and
                    dignity, their individual needs nurtured in a caring and
                    endearing environment.
                  </p>

                  <p>
                    Once two the children eagerly enter the next realm of their
                    development – the over two room, with their focus on a
                    nature based curriculum which is inspired by a combination
                    of Reggio Emilia, Steiner and a fun loving team. The
                    environment is set to provide meaningful and multiple
                    learning opportunities, with the emphasis on engaging in
                    nature and installing a sense of wonder in our young minds.
                    ‘Wonder’ is an important motivator for life long learning.
                  </p>
                  <p>To the team at Just Kidd Inn Too.</p>

                  <p>
                    <i>
                      Well where did 5 years go…..I remember clearly as a first
                      time mother going back to work when I handed my 5 month
                      old baby girl over for her first day at Just Kidd Inn Too.
                      It was so heartbreaking for me but you all welcomed us
                      with open arms and I felt very reassured that my little
                      girl was in the best care. Then of course a few years
                      later our second baby joined the Just Kidd Inn Too family.
                      What a wonderful place and most importantly such amazing
                      staff. You offered an educational, loving, warm and safe
                      environment for both our girls and it really did become a
                      home away from home for them. It was comforting to know
                      they were being well cared for by people that shared the
                      same values, hopes and dreams for our children. You always
                      went the extra mile and had a genuine love for every
                      single one of those children at the centre. Thank you so
                      much also for the wonderful Journals we received for each
                      of the girls,they mean a lot to us. All those precious
                      little moments we missed, we now have something to
                      treasure. Thank you. You have been a huge part of our
                      girl’s lives and we are so grateful for everything you
                      have done for our family over the years.
                    </i>
                  </p>

                  <p>Thank you The Grants xxx</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs
